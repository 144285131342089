import request from '@/utils/request'

var VUE_APP_BASE_API_NEW = process.env.VUE_APP_BASE_API_NEW

// 获取主页配置
export function getsysconfig(params) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/getsysconfig/',
        method: 'get',
        params: params
    })
}


// 获取所有标签
export function BlogTag(params) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/autocode/BlogTag/',
        method: 'get',
        params: params
    })
}


// 获取所有分类
export function BlogCategory(params) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/autocode/BlogCategory/',
        method: 'get',
        params: params
    })
}


// 获取文章列表
export function fetchArticleList(params) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/autocode/BlogPost/',
        method: 'get',
        params: params
    })
}

// 获取文章详情
export function BlogPostDetails(id) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/autocode/BlogPost/'+id,
        method: 'get',
        // params: params
    })
}

// 点赞
export function BlogUpvote(data) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/autocode/BlogUpvote/',
        method: 'post',
        data
    })
}

// 登录
export function login(data) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/login/',
        method: 'post',
        data
    })
}

// 注册-发送验证码
export function semdemail(data) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/semdemail/',
        method: 'post',
        data
    })
}

// 邮箱号注册
export function registeremail(data) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/registeremail/',
        method: 'post',
        data
    })
}

// 邮箱重置密码
export function restpasswordemail(params) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/restpasswordemail/',
        method: 'get',
        params: params
    })
}


// 获取用户个人信息
export function getmyinfo(params) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/getmyinfo/',
        method: 'get',
        params: params
    })
}


// 图片上传
export function uploadimage(data) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/uploadimage/',
        method: 'POST',
        data: data,
		headers: { 'Content-Type': 'multipart/articles-data' },
    })
}

// 修改头像
export function changeavatar(data) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/changeavatar/',
        method: 'post',
        data
    })
}

// 修改昵称
export function setnickname(data) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/setnickname/',
        method: 'post',
        data
    })
}

// 用户注销
export function cancel(data) {
    return request({
        url: VUE_APP_BASE_API_NEW + '/api/app/cancel/',
        method: 'post',
        data
    })
}


export function getArticleByUserId(params) {
    return request({
        url: '/v1/article/selectArticleByUserId',
        method: 'get',
        params: params
    })
}
export function deleteMyArticle(id) {
    return request({
        url: '/v1/article/',
        method: 'delete',
        params: {
            id: id
        }
    })
}
export function getMyArticleInfo(id) {
    return request({
        url: '/v1/article/selectMyArticleInfo',
        method: 'get',
        params: {
            id: id
        }
    })
}
export function readMarkdownFile(data) {
    return request({
        url: '/v1/article/readMarkdownFile',
        method: 'post',
        data
    })
}
export function insertArticle(data) {
    return request({
        url: '/v1/article/',
        method: 'post',
        data
    })
}
export function updateArticle(data) {
    return request({
        url: '/v1/article/',
        method: 'put',
        data
    })
}
export function searchArticle(params) {
    return request({
        url: '/v1/article/search',
        method: 'get',
        params: params
    })
}

export function articleInfo(id) {
    return request({
        url: '/v1/article/info',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function checkCode(code) {
    return request({
        url: '/v1/article/checkCode',
        method: 'get',
        params: {
            code: code
        }
    })
}
export function articleLike(id) {
    return request({
        url: '/v1/article/like',
        method: 'get',
        params: {
            articleId: id
        }
    })
}
export function archive() {
    return request({
        url: '/v1/article/archive',
        method: 'get',
    })
}

export function fetchTagList() {
    return request({
        url: '/v1/tag/',
        method: 'get',

    })
}

export function featchHomeData() {
    return request({
        url: '/v1/',
        method: 'get',
    })
}
export function getHot(type) {
    return request({
        url: '/v1/hot',
        method: 'get',
        params: {
            type: type
        }
    })
}
export function report() {
    return request({
        url: '/v1/report',
        method: 'get',
    })
}
export function getWebSiteInfo() {
    return request({
        url: '/v1/webSiteInfo',
        method: 'get',
    })
}

export function emailLogin(data) {
    return request({
        url: '/oauth/emailLogin',
        method: 'post',
        data
    })
}
export function forgetPassword(data) {
    return request({
        url: '/oauth/forgetPassword',
        method: 'put',
        data
    })
}
export function logout() {
    return request({
        url: '/logout',
        method: 'get'
    })
}
export function openAuthUrl(source) {
    return request({
        url: '/oauth/render/' + source,
        method: 'get'
    })
}
export function wxIsLogin(loginCode) {
    return request({
        url: '/oauth/wechat/is_login',
        method: 'get',
        params: {
            loginCode: loginCode
        }
    })
}
export function getWechatLoginCode() {
    return request({
        url: '/oauth/wechatLoginCode',
        method: 'get',

    })
}
export function sendEmailCode(email) {
    return request({
        url: '/oauth/sendEmailCode',
        method: 'get',
        params: {
            email: email
        }
    })
}
export function emailRegister(data) {
    return request({
        url: '/oauth/emailRegister',
        method: 'post',
        data
    })
}
export function updateUserInfo(data) {
    return request({
        url: '/v1/user/',
        method: 'put',
        data
    })
}
export function getUserInfo(userId) {
    return request({
        url: '/v1/user/info',
        method: 'get',
        params: {
            userId: userId
        }
    })
}
export function selectUserInfoByToken(token) {
    return request({
        url: '/v1/user/selectUserInfoByToken',
        method: 'get',
        params: {
            token: token
        }
    })
}
export function getUserCount(id) {
    return request({
        url: '/v1/user/getUserCount',
        method: 'get',
        params: {
            id: id
        }
    })
}
export function upload(data) {
    return request({
        url: '/file/upload',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/articles-data' },
        data
    })
}
export function featchCategory() {
    return request({
        url: '/v1/category/',
        method: 'get'
    })
}

export function addFeedback(data) {
    return request({
        url: '/v1/feedback/',
        method: 'post',
        data
    })
}
export function getCollect() {
    return request({
        url: '/v1/collect/',
        method: 'get'
    })
}
export function collect(id) {
    return request({
        url: '/v1/collect/collect',
        method: 'get',
        params: {
            articleId: id
        }
    })
}
export function cancelCollect(id) {
    return request({
        url: '/v1/collect/',
        method: 'delete',
        params: {
            articleId: id
        }
    })
}

export function followedUser(userId) {
    return request({
        url: '/v1/followed/insertFollowed',
        method: 'post',
        params: {
            userId: userId
        }
    })
}
export function deleteFollowedUser(userId) {
    return request({
        url: '/v1/followed/deleteFollowed',
        method: 'delete',
        params: {
            userId: userId
        }
    })
}

